<template>
    <div class="py-12 bg-light">

        <control-modal v-bind:show-modal="this.showModal"
                       v-bind:device_id="this.device_id"
                       v-bind:last_live_measurement="this.last_live_measurement"
                       v-bind:imei="this.imei"
                       v-bind:last_received_measurement="this.last_received_measurement"
                       v-bind:weather_service_updated="this.weather_service_updated"
                       v-bind:send_status_email="this.send_status_email"
                       v-bind:battery="this.battery"
        >
        </control-modal>

        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                <div class="p-6 bg-white border-b border-gray-200">
                    <div class="row overflow-x-auto">

                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" href="#wunderground" role="tab" data-toggle="tab" aria-selected="true">Wunderground</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#weathercloud" role="tab" data-toggle="tab">WeatherCloud</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#meteoiot" role="tab" data-toggle="tab">MeteoIot</a>
                            </li>
                        </ul>
                        </div>
                        <div class="row overflow-x-auto">

                        <!-- Tab panes -->
                        <div class="tab-content" style="width:-moz-available">
                            <div role="tabpanel" class="tab-pane fade in show active" id="wunderground">
                                <credentials-table :timezones="timezones" :weather_service="'wunderground'" :show-modal="this.showModal"></credentials-table>
                            </div>
                            <div role="tabpanel" class="tab-pane fade" id="weathercloud">
                                <credentials-table :timezones="timezones" :weather_service="'weathercloud'" :show-modal="this.showModal"></credentials-table>
                            </div>
                            <div role="tabpanel" class="tab-pane fade" id="meteoiot">
                                <MeteoIotTable :timezones="timezones" :weather_service="'meteoiot'" :show-modal="this.showModal"></MeteoIotTable>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>

</template>
<script>
import CredentialsTable from "./CredentialsTable";
import ControlModal from "./ControlModal";
import MeteoIotTable from "./MeteoIotTable";
export default {
    components: {MeteoIotTable, ControlModal, CredentialsTable},
    props: ['timezones'],
    data() {
        return {
            showModal: false,
            device_id: 0,
            last_live_measurement: ' - ',
            last_received_measurement: ' - ',
            weather_service_updated: ' - ',
            send_status_email: 0,
            imei: ' - ',
            battery: ' - '
        }
    },
}
</script>
